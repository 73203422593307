<template>
  <div class="uk-position-bottom uk-position-fixed uk-background-default menu">
    <div
      class="container uk-flex uk-flex-center uk-text-center uk-padding-small"
    >
      <div
        v-for="icon in icons"
        :key="icon.name"
        :class="'uk-width-1-' + icons.length"
      >
        <menu-item
          :icon="icon.name"
          :active="active == icon.link"
          :badge="icon.badge"
          :link="{ name: icon.link }"
        ></menu-item>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem'
import { mapGetters } from 'vuex'

export default {
  name: 'SickyMenu',
  components: {
    MenuItem
  },
  props: ['active'],
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters(['itemsInCart']),
    icons() {
      return [
        { name: 'grid', link: 'home' },
        { name: 'cart', link: 'cart', badge: this.itemsInCart },
        { name: 'commenting', link: 'feedback' }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import './assets/theme.scss';
.menu {
  border-top: 1px solid $global-border;
}
</style>
