<template>
  <div
    :class="['uk-position-relative', 'uk-icon-button', active ? 'active' : '']"
    @click="clicked(link)"
    :uk-icon="icon"
  >
    <span
      v-if="badge"
      class="uk-badge uk-background-secondary uk-position-absolute uk-position-top-right"
      >{{ badge }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    icon: {
      default: 'plus'
    },
    active: {
      default: false
    },
    button: {
      default: false
    },
    badge: {
      default: null
    },
    link: {
      default: {}
    }
  },
  methods: {
    clicked(link) {
      this.$router.push(link).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/theme.scss';

div.uk-icon-button {
  height: 40px;
  width: 40px;
  border: 1px solid $global-primary-background;
  background-color: $global-background;
  color: $global-primary-background;
  &.active {
    background-color: $global-primary-background;
    color: $global-background !important;
  }
  .uk-badge {
    margin-right: -25%;
    margin-top: -25%;
  }
}
</style>
