<template>
  <div class="uk-position-top uk-position-fixed uk-background-default menu">
    <div class="container uk-text-center uk-padding-small uk-text-primary">
      <div class="uk-position-relative">
        <font-awesome-icon
          icon="chevron-left"
          class="uk-position-center-left"
          @click="goBack"
        />
        <span v-if="products">{{ getCurrentRetailer }} - </span>{{ title }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StickyHeader',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Kooperatywa'
    },
    products: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters(['getCurrentRetailer'])
  },
  methods: {
    goBack() {
      window.history.back()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './assets/theme.scss';
.menu {
  z-index: 1000;
}
</style>
